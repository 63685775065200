import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { motion } from 'framer-motion'
import Moment from 'react-moment'
import { Element } from 'react-scroll'
import * as Scroll from 'react-scroll'

const duration = 0.35

const container = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
      delayChildren: duration,
    },
  },
}
const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

let ScrollLink = Scroll.Link;

const Header = ({ siteTitle }) => (

  <motion.header 
    className="container relative z-10"
    initial="hidden" 
    animate="visible"
    variants={container}>
    <Element name="top"></Element>


      <motion.div variants={item} transition="easeInOut" className="top-bar grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl h-12 ">
        <div className="border-b"></div>
        <div className="border-b border-l">
          <div className="flex py-3 px-6 md:px-10">
            <div className="w-1/3 text-sm mob:w-2/3"><Link to="/" className="no-underline">Happy <Moment format="dddd"></Moment></Link></div>
            <div className="w-1/3 text-sm text-center mob:text-right mob:w-1/3"><Link to="/about" className="no-underline">About</Link></div>
            <div className="w-1/3 text-sm text-right mob:hidden "><a href="mailto:hello@benjamincross.co.uk" className="no-underline">hello@benjamincross.co.uk</a></div>
          </div>
        </div>
        <div className="border-b border-l"></div>
      </motion.div>

      <motion.div variants={item} transition="easeInOut" className="grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl h-min">

        <div className="border-b"></div>

        <div  className="border-b border-l  px-6 md:px-10 py-4 grid grid-cols-2 flex flex-row justify-center">
          

          <Link className="bx-wrapper font-bold text-lg md:text-xl" to="/">
            BX - Home
            <motion.div>

              <svg   className="bx" width="129px" height="94px" viewBox="0 0 135 94" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Benjamin Cross</title>
                <defs>
                    <polygon id="path-1" points="66.5214194 93.6029412 0.000559139785 93.6029412 0.000559139785 0.331382353 66.5214194 0.331382353"></polygon>
                    <polygon id="path-3" points="54.7341935 54.3735294 54.7341935 0.0866176471 0.418376344 0.0866176471 0.418376344 54.3735294 54.7341935 54.3735294"></polygon>
                </defs>
                <g id="Location-–-Single" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Desktop-HD" transform="translate(-121.000000, -54.000000)">
                        <rect id="Rectangle" fill="none" x="0" y="0" width="1440" height="848"></rect>
                        <g id="BX" transform="translate(121.000000, 54.000000)">
                            <g id="Group-3" transform="translate(0.000000, 0.311140)">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1"></use>
                                </mask>
                                <g id="Clip-2"></g>
                                <path d="M32.3579785,54.8781471 L19.9981935,54.8781471 L19.9981935,76.5311618 L34.0242151,76.5311618 C41.3852903,76.5311618 46.384,72.0885147 46.384,65.8436618 C46.384,59.3193971 41.3852903,54.8781471 32.3579785,54.8781471 M28.7473333,17.4034412 L19.9981935,17.4034412 L19.9981935,37.8060882 L29.0255054,37.8060882 C36.5235699,37.8060882 41.2455054,34.0591765 41.2455054,27.2568971 C41.2455054,21.8446912 37.358086,17.4034412 28.7473333,17.4034412 M37.4964731,93.6032206 L0.000559139785,93.6032206 L0.000559139785,0.331382353 L30.830129,0.331382353 C51.5224946,0.331382353 60.5498065,12.9621912 60.5498065,25.0369706 C60.5498065,34.3357941 54.1616344,41.1380735 47.2171183,44.0523382 C56.2444301,45.9956471 66.5214194,54.1838088 66.5214194,66.3982941 C66.5214194,84.303 52.2172258,93.6032206 37.4964731,93.6032206" id="fill-b" className="fill" fill="#ffffff" mask="url(#mask-2)"></path>
                            </g>
                            <path
                              d="M94.9732473,87.6266176 L94.8767957,87.5302206 C91.1571183,83.8126471 91.1571183,77.7829412 94.8767957,74.0639706 C98.597871,70.3463971 104.629591,70.3463971 108.350667,74.0639706 L108.447118,74.1603676 C112.166796,77.8793382 112.166796,83.9076471 108.447118,87.6266176 C104.726043,91.3455882 98.6943226,91.3455882 94.9732473,87.6266176" id="fill-o" className="fill" fill="#ffffff"></path>
                            <g id="x" className="x" transform="translate(74.086022, 0.311140)">
                                <mask id="mask-4" fill="white">
                                    <use xlinkHref="#path-3"></use>
                                </mask>
                                <g id="Clip-7"></g>
                                <motion.polygon
                                  initial={{ rotate: 0 }}
                                  animate={{ rotate: 360 }}
                                  transition={{ delay: 1, duration: .65, ease: "easeInOut" }} 
                                  id="fill-x" className="fill" fill="#ffffff" mask="url(#mask-4)" points="54.7346129 13.9971324 41.2817097 0.318529412 27.6792366 13.75125 27.6442903 13.7163235 14.2403118 0.0852205882 0.650419355 13.4355147 14.0292366 27.0386765 0.418376344 40.4616176 13.8698817 54.1402206 27.4821398 40.7172794 27.5073011 40.7438235 40.9126774 54.3735294 54.501172 41.0246324 41.1321398 27.4298529">
                              </motion.polygon>
                            </g>
                        </g>
                    </g>
                </g>
              </svg>

           
            </motion.div>
          </Link> 


          <div className="text-right flex flex-col justify-center">
            <ScrollLink activeClass="active" className="index-btn group self-end" to="index" href="#index" smooth={true} offset={0} duration={200} delay={20}>
              Jump to index
              <div className="grid grid-cols-2 grid-rows-3 border">
                <div className="border-b border-r group-hover:bg-kindLightYellow"></div>
                <div className="border-b group-hover:bg-unidaysGreen"></div>
                <div className="border-b border-r group-hover:bg-hcPurple"></div>
                <div className="border-b group-hover:bg-mrPink"></div>
                <div className="border-r group-hover:bg-rbBlue"></div>
                <div className="group-hover:bg-goodmakersGold"></div>
              </div>

            </ScrollLink>
          </div>

        </div>

        <div className="border-b border-l"></div>

        
      </motion.div>

  </motion.header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
