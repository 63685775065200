import React from "react"


export default function Footer() {
return (

  <div className="index contact grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">

    <div className="border-b"></div>

    <div className="border-b border-l">
   
    
     <div 
      className="md:flex md:flex-row md:flex-wrap md:flex-grow">
       
        <div
          className="group flex md:flex-col w-full ">
          <div className="flex flex-row">
            <div className="py-5 px-3 text-center border-r transition-all">
              <div className="py-4 text-lg w-6">
                <a href="mailto:hello@benjamincross.co.uk" className="blink">
                _
                </a>
              </div>
            </div>
            <a href="mailto:hello@benjamincross.co.uk" className="p-5 py-8 w-full">
              <h3 className="m-0 text-xl lg:text-2xl group-hover:text-textGray transition-all">hello@benjamincross.co.uk</h3>
              <p className="text-sm group-hover:text-textGray transition-all">Say hi... maybe coffee or, let’s face it, Zoom?</p>
            </a>
          </div>
        </div>
        
    </div>

  </div>
  <div className="border-b border-l"></div>
</div>

)}







