import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import Header from "./header"
import Index from "../components/index"
import Footer from "../components/footer"
import Hygiene from "../components/hygiene"
import "../styles/main.css"


const duration = 0.35

const variants = {
  initial: {
    y: 20,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
}






const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // const cursorX = useMotionValue(-100);
  // const cursorY = useMotionValue(-100);

  // const springConfig = { damping: 50, stiffness: 400 };
  // const cursorXSpring = useSpring(cursorX, springConfig);
  // const cursorYSpring = useSpring(cursorY, springConfig);

  // useEffect(() => {
  //   const moveCursor = (e) => {
  //     cursorX.set(e.clientX - 16);
  //     cursorY.set(e.clientY - 16);
  //   };

  //   window.addEventListener("mousemove", moveCursor);

  //     return () => {
  //       window.removeEventListener("mousemove", moveCursor);
  //     };
  // }, []);



  return (
    <>
    
      <Header siteTitle={data.site.siteMetadata.title} />
      <AnimatePresence>
        <motion.main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          data-scroll-container
        >
        {children}
        </motion.main>
        <motion.footer
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit">
          <Index></Index>
          <Footer></Footer>
          <Hygiene></Hygiene>
        </motion.footer>
      </AnimatePresence>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
