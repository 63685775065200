import React from "react"
import { Link } from "gatsby"
import { Element } from 'react-scroll'
import * as Scroll from 'react-scroll'

let ScrollLink = Scroll.Link;

export default function Index() {
return (

<Element name="index">
  <div id="index" className="index grid grid-cols-bx md:grid-cols-bx-md lg:grid-cols-bx-lg xl:grid-cols-bx-xl xxl:grid-cols-bx-xxl">

    <div className="border-b"><div className="text-v uppercase md:pt-8 lg:pt-10 px-1 lg:px-3 tracking-widest mob:hidden">Index</div></div>

    <div className="index__inner border-b border-l">
    
     <div 
      className="md:flex md:flex-row md:flex-wrap md:flex-grow">
       
        <Link
          to="/kind"
          className="group flex md:flex-col md:w-1/2 mob:border-b md:border-r ">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-kindLightYellow text-center border-r  transition-all">
              <div className="text-lg w-6">01</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl md:text-3xl lg:text-4xl group-hover:text-textGray transition-all">Kind</h3>
                <p className="text-sm group-hover:text-textGray transition-all">Brand refresh for a socially concious digital agency.</p>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/unidays"
          className="group flex md:flex-col md:w-1/2  ">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-unidaysGreen text-center border-r  transition-all">
              <div className="text-lg w-6">02</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl lg:text-4xl group-hover:text-textGray transition-all">UNiDAYS</h3>
                <p className="text-sm group-hover:text-textGray  transition-all">Creative designer for Gen-Z oriented student savings platform.</p>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/heart-church"
          className="group flex md:flex-col md:w-1/2 border-t md:border-r ">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-hcPurple text-center border-r  transition-all">
              <div className="text-lg w-6">03</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl lg:text-4xl group-hover:text-textGray transition-all">Heart Church</h3>
                <p className="text-sm group-hover:text-textGray transition-all">Brand development for forward-thinking city church.</p>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/mark-ritchie"
          className="group flex md:flex-col md:w-1/2 border-t ">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-mrPink text-center border-r  transition-all">
              <div className="text-lg w-6">04</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl lg:text-4xl group-hover:text-textGray transition-all">Mark Ritchie</h3>
                <p className="text-sm group-hover:text-textGray transition-all">Brand, digital and print for comedian and podcaster.</p>
              </div>
            </div>
          </div>
        </Link>

        <Link
          to="/rewilding-britain"
          className="group flex md:flex-col md:w-1/2 border-t md:border-r md:border-b ">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-rbBlue text-center border-r  transition-all">
              <div className="text-lg w-6">05</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl lg:text-4xl group-hover:text-textGray transition-all">Rewilding Britain</h3>
                <p className="text-sm group-hover:text-textGraytransition-all">Website design for Britain's leading rewilding charity.</p>
              </div>
            </div>
          </div>
        </Link>

        <a href="#"
          className="group flex md:flex-col md:w-1/2 border-t border-b ">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-goodmakersGold text-center border-r  transition-all">
              <div className="text-lg w-6">06</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl lg:text-4xl group-hover:text-textGray transition-all">Goodmakers</h3>
                <p className="text-sm group-hover:text-textGray transition-all">_COMING SOON!</p>
              </div>
            </div>
          </div>
        </a>

        <Link
          to="/archive"
          className="group flex md:flex-col md:w-1/2 md:border-r mob:border-b">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-archive text-center border-r transition-all">
              <div className="text-xl w-6">&</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl lg:text-4xl group-hover:text-textGray transition-all">Archive</h3>
                <p className="text-sm group-hover:text-textGray transition-all">Just a bunch of stuff from the past.</p>
              </div>
            </div>
          </div>
        </Link>

         <Link
          to="/about"
          className="group flex md:flex-col md:w-1/2  ">
          <div className="flex flex-row">
            <div className="index__no px-3 group-hover:bg-archive text-center border-r  transition-all">
              <div className="text-2xl w-6">*</div>
            </div>
            <div className="index__title w-full transition-all">
              <div>
                <h3 className="m-0 text-3xl lg:text-4xl group-hover:text-textGray transition-all">About</h3>
                <p className="text-sm group-hover:text-textGray transition-all">A little bit more about me and the services I offer</p>
              </div>
            </div>
          </div>
        </Link>
        
    </div>

  </div>
  <div className="border-b border-l">
    <ScrollLink activeClass="active" className="text-v uppercase md:pt-8 lg:pt-10 px-1 lg:px-3 tracking-widest mob:hidden cursor-pointer" to="top" smooth={true} offset={0} duration={200} delay={20}>
      Back to top
    </ScrollLink>
  </div>

</div>
</Element>

)}







